import AlpineImport from "alpinejs";
import { decode } from "blurhash";

export default function (Alpine: typeof AlpineImport) {
  Alpine.directive("blurhash", (el, { expression, modifiers }) => {
    const blurhash = expression;
    if (!blurhash?.length) return;

    const width = getNumber("x", modifiers, 32);
    const height = getNumber("y", modifiers, 32);
    const punch = getNumber("punch", modifiers, 1);

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    const pixels = decode(blurhash, width, height, punch);
    const drawContext = canvas.getContext("2d")!;
    const imageData = drawContext.createImageData(width, height);
    imageData.data.set(pixels);
    drawContext.putImageData(imageData, 0, 0);

    try {
      (el as HTMLElement).style.backgroundImage = `url(${canvas.toDataURL("image/png")})`;
    } catch (e) {
      console.error("Applying blurhash failed for element", el, e);
    }
  });
}

function getNumber(key: string, modifiers: string[], defaultValue: number) {
  if (!modifiers.includes(key)) return defaultValue;

  let value = modifiers[modifiers.indexOf(key) + 1];
  return Number(value);
}
